 /*rem size is relative to its parent 16px == 100% , 10px == 62.5% */
html{
	font-size: 62.5%;
}
body{
	/*background: linear-gradient(125deg,#fff,#f3f3f3 41%,#ededed 0,#fff);*/
	/*background: #fff;*/
    background: #1b1b1b;

}

.main-wrapper{
    background: #fff;
}

.container-fluid{
	padding: 0 !important;
    background: #fff;
}

.header-section{
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #eee;
    font-weight: 700;
 	background-color: #f85a40;
    
}

.brand-text{
	font-size: 1.9rem;
}

.nav-link{
	color: #eee;
}
/*.nav-link.active{
	color : rgba(0,0,0,0.5); 
}*/
.nav-link:hover{
	color : rgba(0,0,0,0.5);
}


.heading {
    font-size: 3.75rem;
    font-weight: 700;
}

.heading-bottom {
    font-size: 1.5rem;
    color: #a6a6a6;
}

@media (max-width: 768px) {
    .heading {
        font-size: 2.75rem;
    }
}

/*======footer===== */
.footer-holder{
    background: #1b1b1b;
    color: #fff;
}
.footer-holder  p{
    font-size: 1.4rem;
}
.footer-holder  h4{
    font-size: 2rem;
}
.footer-holder i.fas {
    font-size: 1.4rem;
    color: #f85a40;
    margin-right: 5px;
}
/*====== END footer===== */

/* ========= Home ===============*/
img{
    width: 100%;
}

.home_right:after {
    border: 5px solid #f85a40;
    content: "";
    display: inline-block;
    left: -45px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.Clients {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    padding: 1rem 0;
    cursor: pointer;
}


@media (max-width: 991px) {
    .home_right:after{
        left: 0px;
    }
}

/* ========= End ===============*/

/* ===== Services page  =====*/
p {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.agile_banner_bottom_grid_left_grid i {
    font-size: 2.2rem;
    color: #fff;
    background: #f85a40;
    width: 60px;
    height: 60px;
    line-height: 60px;
}

.w3_agileits_banner_bottom_pos_grid:hover i {
    color: #333;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

.agileits_banner_bottom_left {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

.wthree_banner_bottom_grid_right h4 {
    font-size: 2rem;
    /*letter-spacing: 0px;*/
    font-weight: 600;
    color: #333;
}

.wthree_banner_bottom_grid_right p {
    line-height: 28px;
    font-size: 1.4rem;
    color: #676767;
    letter-spacing: 1px;
}
/* ===== END Services page  =====*/

/*  =====  Contact US page =========== */

.contact-left{
	background: #1d1d1d;
  padding: 6em 0em;
  height: 37rem;
}

.contact-right{
	height: 37rem;
}

address p{
	font-size: 1.7em;
    color: #eee;
    line-height: 2em;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
}

.contact-left i.fas {
    font-size: 18px;
    color: #f85a40;
    margin-right: 5px;
}

.contact-row{
	margin: 2rem 0;
}
.contact-us-row{
  position: relative;
  padding: 1rem;
  margin: 1rem -15px 0;
  border: solid #f7f7f9;
  font-size: 1.5rem;
}
.form-group .control-label:after {
  content:"*";
  color:red;
}
/* media queries */
@media (max-width: 991px) {
	.contact-left{
	    padding: 4em 0em;
	    height: 32rem;
	}
	.contact-right{
		height: 32rem;
	}
}

@media (max-width: 768px){
    .contact-right{
       padding: 1rem 0;
    }
}
/*  =====  END Contact US page =========== */

/* ============= PRODUCTS ===============*/
.product-heading h2 {
    background-color: #f85a40;
    color: #fff;
    border-radius: 0.5rem;
}

.product-image img{
    max-width: 37rem;
}

.product-specifications ul {
    font-size: 1.5rem;
    font-weight: 500;
}
.product-features span{
    font-size: 1.5rem;
    font-weight: 500;
}
.products-col .row{
    /*border : solid 1px;*/
    border-radius: 1rem;
}
/* ============= END PRODUCTS ===============*/
/* loader for Login */
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;


  /* -webkit-box-shadow: rgba(32, 168, 216, 0.75) 1.5em 0 0 0, rgba(32, 168, 216, 0.75) 1.1em 1.1em 0 0, rgba(32, 168, 216, 0.75) 0 1.5em 0 0, rgba(32, 168, 216, 0.75) -1.1em 1.1em 0 0, rgba(32, 168, 216, 0.5) -1.5em 0 0 0, rgba(32, 168, 216, 0.5) -1.1em -1.1em 0 0, rgba(32, 168, 216, 0.75) 0 -1.5em 0 0, rgba(32, 168, 216, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(32, 168, 216, 0.75) 1.5em 0 0 0, rgba(32, 168, 216, 0.75) 1.1em 1.1em 0 0, rgba(32, 168, 216, 0.75) 0 1.5em 0 0, rgba(32, 168, 216, 0.75) -1.1em 1.1em 0 0, rgba(32, 168, 216, 0.75) -1.5em 0 0 0, rgba(32, 168, 216, 0.75) -1.1em -1.1em 0 0, rgba(32, 168, 216, 0.75) 0 -1.5em 0 0, rgba(32, 168, 216, 0.75) 1.1em -1.1em 0 0;*/
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ============== */